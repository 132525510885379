import React from "react"
import { Link } from "gatsby"


const drafting = () => {
  return (
    <>
      <section>
        <div className="parallax">
          <img src="/img/black-illustration-on-white-printer-paper-716661.jpg" alt="" />
          <div className="overlay"></div>
          <div className="headercontent">
            <h1>2D-DRAFTING & DETAILING</h1>
          </div>
        </div>
      </section>

      <section className="cad">
        <div className="container pt-4">
          <div className="row">
            <div className="col-12 col-lg-12">
              <h4 className="cadcon">2D Drafting & Detailing Services:</h4>
              <p>We can provide you the 2D drawings for your documentation and manufacturing needs. In case of a building design, 2D drafting is an essential document to execute the project. Innoway systems has equipped to do your 2D drafting and drawing for your company in a precise and professional manner.</p>
              <h4 className="cadcon">2D Drafting  Services:</h4>
              <p>We are specialized in 2D drafting and drawing. We draw according to the customer standards. We have the 2D drafting services for every type of design. Our experienced team of drafters and engineers will deliver the 2D drawing and service drafting for your business. So your design plans are in highly capable hands.
Below are the general services we offer for 2D drafting:</p>

              <ul>
                <li>Machine Drawings</li>
                <li>Assembly Drawings</li>
                <li>Preliminary Drawings</li>
                <li>Construction or Working Drawings</li>
                <li>Presentation Drawings</li>
                <li>Manufacturing Drawings</li>
                <li>Fabrication Drawings</li>
              </ul>
              <h4 className="cadcon">Delivery of 2D Drafting Services:</h4>
              <p>You are welcome to discuss with our experts about your requirement to get more information about the project for better decision making. At Innoway systems we do understand the pressing needs of a customer and do not sell our services, but we guide our clients to decide themselves about selecting the service.
Ours is a customized service, if you need 2D drafting & detailing services for your firm, contact us today at Innoway Systems for a suitable 2D drafting & detailing options.</p>
              <div className="cadconversion"> <Link to="/contactus/" className="btn btn-danger">Contact Now</Link><br /> <br /></div>
            </div>

          </div>
        </div>
      </section>

    </>
  )
}

export default drafting
